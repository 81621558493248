<template>
  <div class="main">
    <div class="">
      <div class="title">
        <el-input
          maxlength="15"
          style="width: 150px"
          placeholder="站点名称"
          v-model="plotName"
        ></el-input>
        <el-input
          maxlength="15"
          style="width: 150px; margin-left: 10px"
          placeholder="电桩编号"
          v-model="pileCode"
        ></el-input>
        <!-- <el-input maxlength="15" style="width:150px;margin-left:10px;" placeholder="站点ID" v-model="plotId"></el-input> -->

        <el-select
          v-model="runningStatus"
          clearable
          placeholder="运行状态"
          style="margin-left: 10px"
        >
          <el-option
            v-for="item in runningStatusArr"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <el-date-picker
          style="margin-left: 10px"
          v-model="ketime"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          range-separator="-"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>

        <!-- format="yyyy 年 MM 月 dd 日" -->

        <el-button
          slot="suffix"
          @click="searchData"
          type="primary"
          icon="el-icon-search"
          style="border-radius: 0 4px 4px 0; margin-right: -5px"
          >搜索</el-button
        >
        <el-button
          style="margin-left: 30px;"
          class="import-btn"
          type="primary"
          @click="exporthandlerTotal"
          >导出统计数据
        </el-button>

        <el-button
          style="margin-left: 20px;"
          class="import-btn"
          type="primary"
          @click="exporthandler"
          >导出列表数据
        </el-button>
      </div>
    </div>
    <div class="">
      <div class="userStatisticsMain">
        <div
          class="topHead"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <div class="content-top">
            <div
              class="topItem"
              v-for="(item, index) in topInfoArray"
              :key="index"
              @click="openUrl(item.url)"
            >
              <img :src="item.img" alt="" />
              <div class="flex-right">
                <div class="topItemName">
                  {{ item.name }}
                </div>
                <div class="topItemValue">
                  {{ item.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-box">
      <div class="data">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column
            type="index"
            label="序号"
            align="center"
            width="80"
          ></el-table-column>
          <!-- <el-table-column prop="plotId" align="center" label="站点ID" min-width="80" :show-overflow-tooltip="true"></el-table-column> -->

          <el-table-column
            prop="plotName"
            align="center"
            label="站点名称"
            min-width="100"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="pileName"
            label="电桩编码"
            align="center"
            min-width="160"
          >
            <template slot-scope="scope">
              <div class="num-text">
                {{ scope.row.pileCode }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="pileName"
            label="电桩名称"
            align="center"
            min-width="100"
            :show-overflow-tooltip="true"
          ></el-table-column>

          <el-table-column
            prop="chargeTotalHour"
            label="充电时长"
            align="center"
            min-width="140"
          >
            <template slot-scope="scope">
              <div class="num-text">
                {{ scope.row.chargeTotalHour }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="totalPowerConsumption"
            label="累计电量"
            align="center"
            min-width="80"
          >
            <template slot-scope="scope">
              <div class="num-text">
                {{ scope.row.totalPowerConsumption }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="totalAmount"
            label="累计费用"
            align="center"
            min-width="80"
          >
            <template slot-scope="scope">
              <div class="num-text">
                {{ scope.row.totalAmount }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="chargeAmount"
            label="累计电费"
            align="center"
            min-width="80"
          >
            <template slot-scope="scope">
              <div class="num-text">
                {{ scope.row.chargeAmount }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="serviceAmount"
            label="累计服务费"
            align="center"
            min-width="80"
          >
            <template slot-scope="scope">
              <div class="num-text">
                {{ scope.row.serviceAmount }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="chargeTotalTimes"
            label="充电次数"
            align="center"
            min-width="60"
          >
            <template slot-scope="scope">
              <div class="num-text">
                {{ scope.row.chargeTotalTimes }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="runningStatus"
            align="center"
            label="运行状态"
            min-width="120"
          >
            <template slot-scope="scope">
              <img
                src="@/assets/wifiGreen.png"
                v-if="scope.row.runningStatus == '0'"
                style="width: 20px;height: 20px;"
              />
              <img
                src="@/assets/wifi.png"
                v-else
                style="width: 20px;height: 20px;"
              />
            </template>
          </el-table-column>

          <!-- <el-table-column prop="portStatus" align="center" label="端口状态 " min-width="80" :formatter="portStatusFormat"></el-table-column>  -->
        </el-table>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getChargeTotal } from '@/api/pile';
import { getToken } from '@/utils/auth';
import { getSalesData, getManageTotalData } from '@/api/statistics';
export default {
  name: 'totalList',
  data() {
    return {
      url: process.env.VUE_APP_BASE_API,
      polt: '',
      poltList: [],
      area: '',
      areaList: '',
      city: '',
      cityList: [],
      province: '',
      provinceList: [],
      isDisable: false,
      allPortVisible: false,
      dialogVisible: false,
      ruleDialogVisible: false,
      gzhList: [],
      cardList: [],
      operatorId: this.$route.query.operatorId,
      keyWord: '',
      id: '',
      isAdd: true,
      search: '',
      tableData: [],
      page: 1,
      pageSize: 10,
      loading: true,
      pileStatus: '',
      pileType: '',
      portStatus: '',
      pileCode: '',

      runningStatus: '',
      runningStatusArr: [
        {
          value: '1',
          label: '离线',
        },
        {
          value: '0',
          label: '在线',
        },
      ],

      allPortFormData: {
        type: '',
        code: '',
      },
      ruleFormData: {
        cardRuleId: '',
        wxRuleId: '',
        Id: '',
      },
      formData: {
        id: null,
        pileCode: '',
        pileName: '',
        plot: '',
        plotName: '',
        portCount: null,
        simCard: '',
        simDueTime: '',
        userId: '',
        runningStatus: '0',
        deviceType: '4',
        pileStatus: null,
        pileType: '',
        cpFeeType: 2001,
        electricity: '',
        voltage: '',
        maxPower: '',
        regionCode: '',
      },
      total: 0,
      agentData: [],
      options: [],
      kgList: [{ id: '1', name: '打开' }, { id: '0', name: '关闭' }],

      props: {
        value: 'id',
        label: 'name',
      },
      popUpModalTitle: '新增',
      //新增查询
      plotId: '',
      plotName: '',
      ketime: [],
      exportParams: {},

      topInfoArray: [
        {
          name: '总收益(元)',
          value: '',
          prop: 'sumSales',
          img: require('../../assets/index/total_1.png'),
        },

        {
          name: '实际收益(元)',
          value: '',
          prop: 'realSales',
          img: require('../../assets/index/total_2.png'),
        },
        {
          name: '订单退款(元)',
          value: '',
          prop: 'reSales',
          img: require('../../assets/index/total_3.png'),
        },
        {
          name: '电费(元)',
          value: '',
          prop: 'chargeAmount',
          img: require('../../assets/index/total_4.png'),
        },
        {
          name: '服务费(元)',
          value: '',
          prop: 'serviceAmount',
          img: require('../../assets/index/total_5.png'),
        },
        {
          name: '订单数量',
          value: '',
          prop: 'sumCount',
          img: require('../../assets/index/total_6.png'),
        },
        {
          name: '充电次数',
          value: '',
          prop: 'chargeTimes',
          img: require('../../assets/index/total_7.png'),
        },
        {
          name: '充电时长(小时)',
          value: '',
          prop: 'realHour',
          img: require('../../assets/index/total_8.png'),
        },
        {
          name: '总耗电量(kw/h)',
          value: '',
          prop: 'consumePower',
          img: require('../../assets/index/total_9.png'),
        },
        {
          name: '设备总数',
          value: '',
          prop: 'totalNumbers',
          img: require('../../assets/index/total_10.png'),
        },
        {
          name: '运行设备',
          value: '',
          prop: 'onLine',
          img: require('../../assets/index/total_11.png'),
        },
        {
          name: '离线设备',
          value: '',
          prop: 'offLine',
          img: require('../../assets/index/total_12.png'),
        },
      ],

      topInfoObject: {},
    };
  },
  created() {
    this.getDataList();

    // this.getManageTotalData();
  },
  watch: {},

  //   async mounted() {
  //   // console.log(window);
  //   await getManageTotalData().then(res => {

  //     this.topInfoObject = Object.assign(this.topInfoObject, res.data)
  //   })

  //   await this.topInfoArrayFun()

  // },

  methods: {
    //   topInfoArrayFun() {
    //   this.topInfoArray.forEach(currentItem => {
    //     for (const key in this.topInfoObject) {
    //       // console.log(currentItem);
    //       if (key == currentItem.prop) {
    //         currentItem.value = this.topInfoObject[key]
    //       }
    //     }
    //   });
    //   // console.log(this.topInfoArray);
    // },

    runningStatusFormat(row) {
      if (row.runningStatus == 1) {
        return '离线';
      } else if (row.runningStatus == 0) {
        return '在线';
      }
    },

    portStatusFormat(row) {
      if (row.portStatus == '0') {
        return '离线';
      } else if (row.portStatus == '1') {
        return '故障';
      } else if (row.portStatus == '2') {
        return '空闲';
      } else if (row.portStatus == '3') {
        return '充电';
      } else if (row.portStatus == '4') {
        return '已插枪';
      } else if (row.portStatus == '5') {
        return '占用中';
      }
    },

    exporthandler() {
      this.$confirm('确定将列表导出吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let xhr = new XMLHttpRequest();
        xhr.open('post', this.url + '/chargingpile/downloadChargeTotal', true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('token', getToken()); // 将token添加到请求头
        // xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function() {
          if (this.status == 200) {
            var blob = this.response;
            var a = document.createElement('a');
            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = '收益统计' + new Date().getTime();
          }
          a.click();
          window.URL.revokeObjectURL(url);
        };
        console.log(JSON.stringify(this.exportParams), 'this.exportParams');
        xhr.send(JSON.stringify(this.exportParams));
      });
    },

    exporthandlerTotal() {
      this.$confirm('确定将数据导出吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let xhr = new XMLHttpRequest();
        xhr.open(
          'post',
          this.url + '/chargingpile/downloadManageTotalData',
          true
        );
        xhr.responseType = 'blob';
        xhr.setRequestHeader('token', getToken()); // 将token添加到请求头
        // xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function() {
          if (this.status == 200) {
            var blob = this.response;
            var a = document.createElement('a');
            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = '经营统计' + new Date().getTime();
          }
          a.click();
          window.URL.revokeObjectURL(url);
        };
        console.log(JSON.stringify(this.exportParams), 'this.exportParams');
        xhr.send(JSON.stringify(this.exportParams));
      });
    },

    searchData() {
      this.page = 1;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      this.ketime = Array.isArray(this.ketime) ? this.ketime : [];
      let param = {
        startTime: this.ketime[0],
        endTime: this.ketime[1],
        plotId: this.plotId,
        plotName: this.plotName,
        stopStatus: this.stopStatus,
        pileCode: this.pileCode,
        pileType: this.pileType,
        pileStatus: this.pileStatus,
        runningStatus: this.runningStatus,
        portStatus: this.portStatus,
        userId: this.operatorId,
        deviceType: 4,
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      this.exportParams = param;
      getChargeTotal(param).then((res) => {
        if (res.data && res.data.records.length >= 0) {
          this.tableData = [...res.data.records];
          this.total = res.data.total;
        }

        this.loading = false;
      }),
        getManageTotalData(param).then((res) => {
          this.topInfoObject = Object.assign(this.topInfoObject, res.data);

          this.topInfoArray.forEach((currentItem) => {
            for (const key in this.topInfoObject) {
              if (key == currentItem.prop) {
                currentItem.value = this.topInfoObject[key];
              }
            }
          });
        });

      console.log('3333', this.topInfoArray);

      // this.topInfoArrayFun()
    },

    handleCurrentChange(val) {
      this.page = val;
      this.getDataList();
    },
  },
};
</script>
<style lang="scss" scoped>
.num-text {
  font-size: 14px;
  color: #337ab7;
}
// 去除input Number的箭头
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type='number'] {
  -moz-appearance: textfield;
}
.title {
  display: flex;
  flex-direction: row;

  .import-btn,
  .switch-btn {
    img {
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }
  }
}

// .data {
//   margin-left: 20px;
//   margin-top: 20px;
//   margin-right: 10px;
// }

.tree {
  position: absolute;
  z-index: 10;
  top: 40px;
  width: 300px;
}

// .is-disabled .el-input__inner {
//   background: #22425b !important;
//   border-color: #22425b !important;
// }

// .el-button--primary {
//   background-color: #02529d;
//   border: none;
// }

.userStatisticsMain {
  margin-top: 20px;
  width: 100%;
  height: 100%;
  position: relative;

  .topHead {
    width: 100%;
    .content-top {
      width: 100%;
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .topItem {
        img {
          padding-left: 10px;
          height: 54px;
          height: 54px;
          margin-right: 16px;
        }
        &:nth-child(6n) {
          margin-right: 0;
        }
        margin-right: 16px;
        background-color: #fff;
        padding: 10px;
        width: calc(16.6% - 13px);
        margin-bottom: 19px;
        height: 128px;
        display: flex;
        align-items: center;
        font-size: 20px;
        color: #666666;
        .topItemName {
          font-size: 14px;
          color: #000000;
          opacity: 0.45;
          margin-bottom: 20px;
        }
      }
    }
  }
  .chart {
    // width: 100%;
    height: 350px;
  }

  .chart2 {
    // width: 100%;
    height: 350px;
  }

  .table2 {
    width: 100%;
  }

  .content {
    height: calc(100% - 20px);
    width: 100%;
    margin-top: 10px;
  }

  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  @keyframes backgroundAnimation {
    from {
      background-color: #10223a;
    }

    to {
      background-color: #1a5cd6;
    }
  }

  @keyframes colorAnimation {
    from {
    }

    to {
      color: #fff;
    }
  }
}

::v-deep .el-dialog {
  width: 20%;
}
</style>
