<template>
    <div class="manage-body">
        <div class="search-content">
            <el-form ref="serachForm" :model="searchForm" label-suffix=":" label-width="14px">
                <div style="display: flex;">
                    <el-row :gutter="8" type="flex" style="flex:1;flex-wrap:wrap;">
                        <el-col :span="8">
                            <el-form-item>
                                <div style="display: flex;">
                                    <span style="margin-right: 8px; white-space: nowrap;">站点名称</span>
                                    <el-input maxlength="11" v-model="searchForm.plotName" placeholder="请输入"
                                        clearable></el-input>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item>
                                <div style="display: flex;">
                                    <span style="margin-right: 8px; white-space: nowrap;">告警等级</span>
                                    <el-select v-model="searchForm.gradeId" filterable placeholder="请选择"
                                        style="width: 100%;" clearable>
                                        <el-option v-for="(item, index) in gradeList" :key="index" :label="item.type"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="timeRange" required ref="timeRange">
                                <div style="display: flex;">
                                    <span style="margin-right: 8px; white-space: nowrap;">告警时间</span>
                                    <el-date-picker style="width: 100%;" v-model="searchForm.timeRange"
                                        :value-format="'yyyy-MM-dd'" type="daterange" range-separator="至"
                                        start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <div style="margin:0 12px; width: 1px;height: 80px;background-color: #E5E6EB;"></div>
                    </el-row>
                    <el-row>
                        <div style="display: flex;">
                            <el-button @click="searchData" type="primary">查询</el-button>
                            <el-button @click="resetData" plain style="margin-left:8px;">重置</el-button>
                            <el-button @click="resetData" plain style="margin-left:8px;">导出</el-button>
                        </div>
                    </el-row>
                </div>
            </el-form>
        </div>
        <!-- 列表 -->
         <div class="table-body">
            <div>
                <el-button @click="searchData" type="primary">批量处理</el-button>
            </div>
            <div class="table-list">
                    <el-table v-loading="loading" :data="tableData"
                        :header-cell-style="{ background: '#F7F8FA !important', color: '#1D2129  !important' }"
                        style="width: 100%">
                        <el-table-column type="index" label="序号" align="center" width="60"></el-table-column>
                        <el-table-column prop="lineName" label="告警名称" align="center" min-width="130"
                            show-overflow-tooltip></el-table-column>
                        <el-table-column prop="currentCeilStr" label="告警等级" align="center" min-width="100"
                            show-overflow-tooltip></el-table-column>
                        <el-table-column prop="ammeterQty" label="设备类型 " align="center"
                            min-width="100"></el-table-column>
                        <el-table-column prop="connectorDcQty" label="设备名称 " align="center"
                            min-width="100"></el-table-column>
                        <el-table-column prop="connectorAcQty" align="center" label="告警码"
                            min-width="130"></el-table-column>
                        <el-table-column prop="userName" label="发生阶段 " align="center" min-width="100"></el-table-column>
                        <el-table-column prop="userName" label="是否已恢复" align="center"
                            min-width="100"></el-table-column>
                        <el-table-column prop="userName" label="告警时间" align="center"
                            min-width="100"></el-table-column>
                        <el-table-column prop="userName" label="告警来源" align="center" min-width="100"></el-table-column>
                        <el-table-column prop="userName" label="关联订单" align="center" min-width="100"></el-table-column>
                        <el-table-column prop="userName" label="持续时长" align="center" min-width="100"></el-table-column>
                        <el-table-column prop="userName" label="操作" align="center" min-width="100">
                            <template slot-scope="scope">
                                        <span @click="handlerAddDelItem(scope.$index)"
                                            style="color: #86909C;font-size: 14px;cursor: pointer;">详情</span>
                                    </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page" :page-size="listPageSize" layout="total, prev, pager, next,sizes, jumper"
                        :total="total"></el-pagination>
                </div>
         </div>
         <!-- 操作弹框 -->
         <el-drawer title="告警详情" :visible.sync="dialogVisibleAction" :direction="rtl" :modal="false">
                <div class="drawer-phone">
                    <div> <img src="@/assets/dreaw-icon.png" alt=""> 车主手机号: 16346461346</div>
                </div>
                <div>
                    <div class="border-style">
                        <div class="radiu-body"></div>
                    </div>
                    <div class="drawer-detail">
                        <div class="tips-body2">
                            提现失败：微信返回的失败原因
                        </div>
                        <!-- 时间线 -->
                        <div class="withdrawal-line">
                            <el-timeline>
                                <el-timeline-item v-for="(activity, index) in timeData" :key="index"
                                    :icon="activity.icon" :type="activity.type" :color="activity.color"
                                    :size="activity.size">
                                    <!-- :timestamp="activity.timestamp" -->
                                    <div class="line-content">
                                        <div class="title">{{ activity.content }}</div>
                                        <div class="time">{{ activity.timestamp }}</div>
                                    </div>
                                    <div class="describe">1.00元 <span>(原路退回)</span> </div>
                                </el-timeline-item>
                            </el-timeline>
                        </div>
                    </div>
                    <div class="footer-close-btn">
                        <el-button>关闭</el-button>
                    </div>
                </div>
            </el-drawer>
    </div>
</template>

<script setup>
import { 
    ref, 
    reactive, 
    toRefs 
} from 'vue'

const dialogVisibleAction = ref(false)
const data = reactive({
    searchForm: {
        plotName: '',
        gradeId: '',
        marketId: '',
        timeRange: '',
        pageNo: 1,
        pageSize: 10,
    },
    gradeList: [
        {
            id: 1,
            type: '严重'
        },
        {
            id: 2,
            type: '一般'
        },
        {
            id: 3,
            type: '紧急'
        },
    ]
})

const { searchForm, gradeList } = toRefs(data)

function searchData() { //查询

}
function resetData() { //重置

}

</script>

<style lang="scss" scoped>
.manage-body{
    padding: 16px;
    .search-content{
        padding: 10px 8px;
        background-color: #fff;
    }
    .table-body{
        margin-top: 12px;
        padding: 16px;
        background-color: #fff;
        .table-list{
            margin-top: 12px;
        }
    }
}
</style>